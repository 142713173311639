import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-phases-detail',
  templateUrl: './phases-detail.component.html',
  styleUrls: ['./phases-detail.component.css' , "../../app.component.css"]
})
export class PhasesDetailComponent implements OnInit {

  phaseName:string;
  descripcion:string = 'El ciclo de vida de todo proyecto se estructura en torno a cinco fases: inicio, planificación, ejecución, seguimiento y cierre. En esta guía queremos explicar en que consiste cada una , cuál es su objectivo, de que actividades se componen y cuales son los documentos esenciales que se prepararán en cada momento.';

  constructor(private route:ActivatedRoute, private router:Router) {
    this.phaseName = localStorage.getItem('phaseName');
   }

  ngOnInit() {
  }

  goProjectTasks(){
    this.router.navigate(['project-tasks']);
  }

}
