import { ActionType } from './../enums/ActionType';
import { Entity, Column, PrimaryGeneratedColumn } from 'typeorm/browser';

@Entity()
export class QueueItem {

  public static className = 'QueueItem';

  @PrimaryGeneratedColumn()
  public id: number;

  @Column()
  public entityIdLocal: number; // The (frontend) id of the queued entity

  @Column('string')
  public entityName: string;

  @Column()
  public createDate: string;

  constructor (entityName: string, entityIdLocal: number) {
    this.entityName = entityName;
    this.entityIdLocal = entityIdLocal;
    this.createDate = (new Date).toString();
  }
}
