import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../../app.component.css']
})
export class LoginComponent implements OnInit {

  myGroup: FormGroup;

  // Navigator.onLine retornará True o False en función de si tenemos conexión a internet.
  public isOnline: boolean = navigator.onLine;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    public router: Router,
    private portfolioService: PortfolioService
  ) {
    console.log('Loading login screen...');

    this.myGroup = this.fb.group({
      'email': ['', [Validators.required]],
      'pass': ['', [Validators.required]],
    });
  }

  async ngOnInit() {

    const currentUrl = window.location.href;
    const regex = new RegExp("id_token=([^&]*)");
    const results = regex.exec(currentUrl);

    if (results !== undefined && results !== null && results.length >= 2) {
      const token = results[1];
      console.log(token);

      this.portfolioService.setAccessToken(token); // TODO: refactor
      this.portfolioService.httpFindAll().then(data => {
        console.log(data);
      });
    }
  }

  async login() {

    this.authService.login('', '').then(data => {});

    /*
    this.authService.getToken().subscribe(data => {
      console.log(data);
    });
    */

    if (this.myGroup.valid) {
      const email = this.myGroup.get('email').value;
      const pass = this.myGroup.get('pass').value;

      if (await this.authService.login(email, pass)) {
        this.goListProject();
      }
    }

   this.goListProject();
  }

  getErrorEmailMessage() {
    return this.myGroup.get('email').hasError('required')
      ? 'Introduzca su email'
      : this.myGroup.get('email').hasError('error')
      ? 'Usuario no válido'
      : '';
  }

  getErrorPassMessage() {
    return this.myGroup.get('pass').hasError('required')
      ? 'Introduzca su contraseña'
      : this.myGroup.get('pass').hasError('error')
      ? 'Contraseña no valida'
      : '';
  }

  getErrorMessage(name: string) {
    return this.myGroup.get(name).hasError('required')
      ? 'Debe rellenar este campo.'
      : this.myGroup.get(name).hasError('error')
      ? 'Campo no válido.'
      : '';
  }

  goListProject() {
    this.router.navigate(['portfolios']);
  }
}
