import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';


document.addEventListener('deviceready', function() {
  console.log("Device is ready earlier");
}, false);

if (environment.production) {
  enableProdMode();
}
console.log("Run main.ts");
const runningOnMobile = () => document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1;

const bootstrapApp = () => {
  console.log("Device is ready");
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
};

if (runningOnMobile()) {
  console.log("Running on mobile");
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
  // If running inside android or ios, must listen to the cordova deviceready event to start the app
  document.addEventListener('deviceready', bootstrapApp, false); // TODO: Was 'false' originally

} else {
  console.log("Running on web");
  // If running inside the web browser, just bootstrap the app
  bootstrapApp();
}


