import { Component, OnInit } from '@angular/core';

export interface Job {
  name;
}

@Component({
  selector: 'app-project-tasks',
  templateUrl: './project-tasks.component.html',
  styleUrls: ['./project-tasks.component.css'  , "../../app.component.css"]
})
export class ProjectTasksComponent implements OnInit {

  panelOpenState = true;
  jobs: Job[];

  constructor() {

    this.jobs = [];
    this.simulateJobs();
   }

  ngOnInit() {
  }

  simulateJobs(){
    for(let i = 0; i < 3; i++){
      let job = {
        name: 'Oficio '+(i+1)
      }

      this.jobs.push(job);

    }
  }

}
