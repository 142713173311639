import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Project } from 'src/app/models/Project';
import { Router, NavigationExtras } from '@angular/router';
export interface Project {id;
  name;
  percent;
  dateini;
  datefin;
  status;
  incident;
}
@Component({
  selector: 'app-resources-tasks',
  templateUrl: './resources-tasks.component.html',
  styleUrls: ['./resources-tasks.component.css', '../../app.component.css']
})
export class ResourcesTasksComponent implements OnInit {
  state: boolean;
  alter1: boolean;
  alter2: boolean;
  Fecha: boolean;
  Recursos: boolean;
  Oficios: boolean;
  Estado: boolean;
  projectName: string;
  projectId: string;
  constructor(private router: Router) {
    this.alter1 = true;
    this.alter2 = true;
    this.Fecha = true;
    this.projectName = localStorage.getItem('projectName');
    this.projectId = localStorage.getItem('projectId');

  }
  ngOnInit() {
  }
  goResources() {
    this.router.navigate(['projects/' + this.projectId + '/resources'])
    }
clickFecha() {
  this.Fecha = true;
  this.Recursos = false;
  this.Oficios = false;
  this.Estado = false;}
clickRecursos() {
  this.Fecha = false;
  this.Recursos = true;
  this.Oficios = false;
  this.Estado = false;}
clickOficios() {
  this.Fecha = false;
  this.Recursos = false;
  this.Oficios = true;
  this.Estado = false;}
clickEstado() {
  this.Fecha = false;
  this.Recursos = false;
  this.Oficios = false;
  this.Estado = true;}
}
