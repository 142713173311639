import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  /**
   * Encapsulates the localStorage service.
   */
  constructor() {
    // console.log('localStorage object: ' + localStorage);
  }

  public getItem(key: string): any {
    return localStorage.getItem(key);
  }

  public setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  public setItemIfNew(key: string, value: any) {
    if (this.getItem(key) === null || this.getItem(key) === undefined) {
      this.setItem(key, value);
    }
  }
}
