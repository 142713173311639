import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { PageEvent } from '@angular/material';
import { stat } from 'fs';
import { async } from 'q';

export interface Project {id;
  name;
  percent;
  dateini;
  datefin;
  status;
}

@Component({
  selector: "app-project-alternator",
  templateUrl: "./project-alternator.component.html",
  styleUrls: ["./project-alternator.component.css", "../../app.component.css"]
})
export class ProjectAlternatorComponent implements OnInit {
  projects: Project[];
  alter: boolean;
  pageEvent: PageEvent;
  columnsToDisplay: string[] = ['id', 'name', 'dateini', 'datefin', 'status', 'incident'];
  lines: string[] = ['Linea 1', 'Linea 2', 'Linea 3']
  constructor(public router: Router) {
    this.projects = [];
    this.simulateProject();
    this.alter = true;
    this.pageEvent = {
      pageIndex: 0,
      pageSize: 10,
      length: 100
    };
  }
  ngOnInit() {}

  simulateProject() {
    for (let i = 0; i < 20; i++) {
      const rand1 = Math.random();
      if (rand1 < 0.5) {
         var stats = "PARADO"
        } else { var stats = "ACTIVO"
      };
      let project = {id: i+1,
        name: "Nombre Proyecto "+(i+1),
        percent: Math.floor(Math.random()*100)+'%',
        dateini: "21-1-19",
        datefin: "21-7-19",
        status: stats,
        incident: Math.floor(Math.random()*2-1)
      };

      this.projects.push(project);
    }
  }
    goDashboard() {
      this.alter = !this.alter;
    }
  goProjectDetail(projectName: string, id: number) {
    localStorage.setItem('projectName', projectName);
    this.router.navigate(['projects/' + id]);
  }
}
