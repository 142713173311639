import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css' , "../../app.component.css"]
})
export class TaskDetailComponent implements OnInit {

  taskName:string;
  descripcion:string="La tarea es un término empleado para referirse a la práctica de una obligación o a la realización de una actividad sea en el ámbito educativo en eel hogar y también en el ámbito laboral";
  taskProgress:string="En progreso";


  constructor(private router:Router) {
    this.taskName = localStorage.getItem('taskName');
   }

  ngOnInit() {
  }

  goIncidents(){
    this.router.navigate(['incidents']);
  }

}
