import { IQueueableEntity } from './IQueueableEntity';
import { Entity, PrimaryGeneratedColumn, Column } from 'typeorm/browser';

@Entity()
export class Task implements IQueueableEntity {

  public className = 'Task';

  @PrimaryGeneratedColumn()
  public idLocal: number; // Frontend DB id

  @Column({nullable: true})
  public idBack: number; // Backend id

  @Column()
  public name: string;

  @Column({nullable: true})
  public date: string;

  @Column({nullable: true})
  public incidentId: number;

  @Column({nullable: true})
  public incidentIdLocal: number;

  @Column({nullable: true})
  public stageId: number;

  @Column({nullable: true})
  public stageIdLocal: number;

  @Column('boolean')
  public deleted = false; // Logical delete

  constructor ({name = 'Sample task'} = {}) {
    this.name = name;
  }
}
