import { DeviceService } from './../device/device.service';
import { TaskHttpService } from './task-http.service';
import { Injectable } from '@angular/core';
import { Repository } from 'typeorm/browser';
import { Task } from 'src/app/models/Task';
import { AuthService } from '../auth.service';
import { QueueService } from '../queue.service';
import { DatabaseService } from '../database.service';
import { SynchronizeService } from '../synchronize.service';
import { IQueueableService } from '../queueable.interface';
import { NotifyService } from '../notify.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService implements IQueueableService {

  private localRepository: Repository<Task>;

  constructor(
    private auth: AuthService,
    private queue: QueueService,
    private database: DatabaseService,
    private taskHttp: TaskHttpService,
    private notify: NotifyService,
    private device: DeviceService
  ) {
  }

  /**
   * Does all async config that cannot be done in the constructor.
   */
  private async setup() {
    if (!this.localRepository) {
      this.localRepository = await this.database.getRepository<Task>((new Task).className);
    }
  }

  async findOne(idLocal: number) {
    await this.setup();

    try {
      return this.localRepository.findOne({ idLocal: idLocal });
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  /**
   * Create a new task in local scope and upload it to the backend or, if offline, to the waiting queue.
   * @param task  The task to create.
   */
  async create(task: Task): Promise<Task> {
    await this.setup();

    try {
      let createdTask = task;
      if (this.device.hasConnection()) {
        // If connected to the internet, perform http call.
        createdTask = await this.taskHttp.create(task).toPromise();
      } else {
        // If no internet, add to the queue so it can be synced later.
        await this.queue.addItem((new Task).className, task.idLocal);
      }
      // Either way, update local db (it its successful).
      return this.localRepository.create(createdTask);

    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  async update(task: Task) {
    await this.setup();

    try {
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  async delete(task: Task) {
    await this.setup();

    try {
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  createInBackend(task: Task) {
    return this.taskHttp.create(task);
  }

  updateInBackend(task: Task) {
    return this.taskHttp.update(task);
  }

  deleteInBackend(task: Task) {
    return this.taskHttp.delete(task);
  }

}
