import { Component, AfterViewInit , OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Router } from '@angular/router';

export interface Incidents {
  name: string;
  updated: Date;
}

export interface Project {
  name;
  percent;
  date;
}

@Component({
  selector: 'app-incidents',
  templateUrl: './list-incidents.component.html',
  styleUrls: ['./list-incidents.component.css' , '../../app.component.css']
})
export class ListIncidentsComponent implements OnInit {

  nombre: string;
  removeFlag = false;
  incidents: Incidents[];

  projects: Project[];

  swipedIncidentId: number;

  messageSnackbar = 'Operación realizada con éxito';
  actionSnackBar = 'OK';

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {
    this.incidents = [];
    this.simulateIncident();

    this.projects = [];
    this.simulateProject();
  }



  ngOnInit() {}

  simulateIncident() {
    for (let i = 0; i < 20; i++) {
      let incident = {
        name: "Texto corto Incidencia",
        updated: new Date('1/1/19'),
      };

      this.incidents.push(incident);
    }
  }

  simulateProject() {
    for (let i = 0; i < 20; i++) {
      let project = {
        name: "Nombre Proyecto "+(i+1),
        percent: "50%",
        date: "21 Enero"
      };

      this.projects.push(project);
    }
  }

  swipeleft(i){
    this.swipedIncidentId = i;
  }

  view(){

  }

  check(){

  }

  delete(){
    this.openDialog();
  }

  goNewIncident(){
    this.router.navigate(['new-incident']);
  }


  /********* DIALOGOS ********** */

  openDialog(): void {

    const dialogRef = this.dialog.open(DeleteIncidentDialog, {
      width: '80%',

      data: {

      }

    });

    dialogRef.afterClosed().subscribe(result => {

    });

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top'
    });
  }
}

export interface DeleteIncidentData {

}

@Component({
  selector: 'delete-incident',
  templateUrl: 'delete-incident.html',
  styleUrls: ['./list-incidents.component.css']
})
export class DeleteIncidentDialog implements AfterViewInit{

  messageSnackbar = 'Operación realizada con éxito';
  actionSnackBar = 'OK';

  constructor(public dialogRef: MatDialogRef<DeleteIncidentData>, private snackBar: MatSnackBar,private _focusMonitor: FocusMonitor,
    @Inject(MAT_DIALOG_DATA) public data: DeleteIncidentData) {
  }

  ngAfterViewInit() {
    this._focusMonitor.stopMonitoring(document.getElementById('yesButtonDialog'));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  yesClick() {
    this.dialogRef.close();
    this.openSnackBar(this.messageSnackbar, null, 'blue-snackbar');
  }

  openSnackBar(message: string, action: string, className: string) {
    this.snackBar.open(message, action, {
      duration: 1100,
      verticalPosition: 'top',
      panelClass: [className]
    });
  }


}









