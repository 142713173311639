import { MatExpansionModule, MatExpansionPanel} from '@angular/material/expansion';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css', '../../app.component.css']
})
export class ResourcesComponent implements OnInit {
  alter1: boolean;
  alter2: boolean;
  projectName: string;
  constructor() {
    this.projectName = localStorage.getItem('projectName');
  }
  ngOnInit() {
  }

}
