import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public readonly apiUrl = 'https://avintia.implemento.es';
  public readonly apiUrlTest = 'https://avintiabi4api-staging.azurewebsites.net';

  public readonly debugMessages = true;

  /**
   * Checks if the app is currently running in a device instead of in the browser.
   */
  public runningInMobile() {
    return document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1;
  }
}
