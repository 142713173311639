import { Injectable } from '@angular/core';

/**
 * The logic in this service initially belonged to sync service, but it hwas placed here due to
 * circular dependencies.
*/

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private isConnected = false;

  constructor() {

    /*
     * Online/offline events are used in both the browser and by the cordova-plugin-network-information.
     * Therefore, there's no need to check if we are in the browser or in the mobile
     */
    document.addEventListener('online', () => {
      this.isConnected = true;
    }, false);

    document.addEventListener('offline', () => {
      this.isConnected = false;
    }, false);
  }

  hasConnection(): boolean {
    return this.isConnected;
  }
}
