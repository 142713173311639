import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface SincronizeData {
  date: string;
  hour: string;
  success: string;
  user: string;
}
const tabledata: SincronizeData[] = [
  {date: '10 Abril 19', hour:'10:11', success: 'Si', user: 'John Doe'},
  {date: '10 Abril 19', hour:'10:13', success: 'No', user: 'John Doe'},
  {date: '10 Abril 19', hour:'10:17', success: 'Si', user: 'John Doe'},
]
@Component({
  selector: 'app-sincronizer',
  templateUrl: './sincronizer.component.html',
  styleUrls: ['./sincronizer.component.css', '../../app.component.css']
})

export class SincronizerComponent implements OnInit {
  projectName: string;
  displayedColumns: string[] = ['Fecha', 'Hora', 'Éxito', 'Usuario'];
  dataSource = tabledata;
  constructor(private router: Router) { this.projectName = localStorage.getItem('projectName');}

  ngOnInit() {
  }
  goQueue(){
    this.router.navigate(['queue']);
  }

}
