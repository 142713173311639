import { WebStorageService } from './web-storage.service';
import { ConfigService } from './../../../config.service';
import { Injectable } from '@angular/core';
import { createConnection, getRepository, Repository } from 'typeorm/browser';
import { User } from '../models/User';
import { Task } from '../models/Task';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  private connection: any;

  constructor(
    private config: ConfigService,
    private notify: NotifyService
    ) {
  }

  /**
   * Setup a DB connection. Creates one if is not currently created.
   */
  private async setupConnection() {
    if (!this.connection) {
      try {

        const connectionConfig = {
          entities: [ Task, User ],
          synchronize: true,
          logging: false,
          type: null
        };

        if (this.config.runningInMobile()) {
          // If running in mobile, we use a sqlite DB (cannot run in the browser)
          connectionConfig['type'] = 'sqlite';
          connectionConfig['database'] = 'db/avintia-tablero-capataz.db3';
        } else {
          // If running in browser, use non persistent SQLite wrapper: sqljs
          const dbKey = 'avintia-db-browser';
          connectionConfig['type'] = 'sqljs';
          connectionConfig['location'] = dbKey;
          connectionConfig['autoSave'] = true;
        }

        this.connection = await createConnection(connectionConfig);
      } catch (error) {
        this.notify.error(error.message);
        this.notify.logError(error);
      }
    }
  }

  /**
   * Get a repository for a given entity, only if there's a connection.
   * @param type The type of the entity that we want to handle. Must be the same as "T".
   */
  public async getRepository<T>(typeName: string) {
    await this.setupConnection();
    if (this.connection === undefined) {
      console.error('Could not connect to the local DB.');
      return;
    }
    return this.connection.getRepository(typeName) as Repository<T>;
  }

  public async getConnection() {
    await this.setupConnection();
    return this.connection;
  }

}
