import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css' , '../../app.component.css']
})
export class ConfigurationComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  closeSession() {
    this.router.navigate(['login']);
  }

}
