import { SecretService } from './secret.service';
import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { ConfigService } from 'config.service';
import { AdalService } from 'ng2-adal/dist/core';
import * as adal from 'adal-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Auth is made with ADAL (Azure AD Authentication Library) and OAuth2.
  */

  // authContext: adal.AuthenticationContext;

  constructor(
    private configService: ConfigService,
    private secretService: SecretService,
    private adalService: AdalService
  ) {
    // Initalize Azure AD service.
    this.adalService.init(this.secretService.adalConfig);
    this.adalService.handleWindowCallback();
    this.adalService.getUser();

    console.log('Loading auth service...');
  }

  public async login(username: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.adalService.login();

      const obs = this.adalService.acquireToken('avintia.onmicrosoft.com');
      obs.map( token => token.toString() );

      resolve(true);
    });
  }

  public async logout() {

  }

  public async getCurrentUser() {
    return new User();
  }

  public accessToken(): Promise<string> {
    return new Promise<string>(resolve => {
      const obs = this.adalService.acquireToken('avintia.onmicrosoft.com');
      obs.subscribe(token => resolve(token.toString()));
      /*obs.map(token => {
        resolve(token.toString());
      });*/
    });
  }

  public get isAuthenticated(): boolean {
    return false;
  }
}
