import {Component, NgZone, ViewChild, OnInit, Inject} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'
import { Router } from '@angular/router';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'incident-popup',
  templateUrl: './new-incident.component.html',
  styleUrls: ['./new-incident.component.css' , '../../app.component.css']
})

export class IncidentPopup {
  myGroup;
  name;
  description;
  nameFC = new FormControl("", [Validators.required]);
  descriptionFC = new FormControl("", [Validators.required]);
  constructor(
    public dialogRef: MatDialogRef<IncidentPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.myGroup = new FormGroup({
      firstName: new FormControl()
    });}

  ngOnInit() {}

  getErrorNameMessage() {
    return this.nameFC.hasError("required")
      ? "Introduzca su email"
      : this.nameFC.hasError("error")
      ? "Usuario no válido"
      : "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  save(){
    //this.router.navigate(['incidents']);
  }
}

@Component({
  selector: 'app-new-incident',
  templateUrl: './new-incident-button.html',
  styleUrls: ['./new-incident.component.css' , '../../app.component.css']
})

export class NewIncidentButton {


  constructor(public dialog: MatDialog, private router:Router) {}
  openDialog(): void {
    const dialogRef = this.dialog.open(IncidentPopup, {
      width: '100vw',
      height: '100vw',
    });

    /*dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });*/
  }
}
