import { SearchbarService } from './../../services/searchbar.service';
import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';
import { Portfolio } from 'src/app/models/Portfolio';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  portfolios$: Observable<Portfolio[]>;
  private searchTerms = new Subject<string>();
  constructor(private SearchbarService: SearchbarService) { }

  strings: ['hola', 'hola2', 'hola3'];

  search (term: string): void {
    this.searchTerms.next(term);
  }

  ngOnInit(): void {
    /*
    this.portfolios$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.SearchbarService.search(term)),
    );
    */
  }

}
