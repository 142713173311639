import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

export interface Task {
  name;
  status;
  icon;
  class;
}

@Component({
  selector: "app-tasks-by-job",
  templateUrl: "./tasks-by-job.component.html",
  styleUrls: ["./tasks-by-job.component.css"]
})
export class TasksByJobComponent implements OnInit {
  taskStatus: string;
  tasks: Task[];

  constructor(private router:Router) {
    this.tasks = [];
    this.simulateTask();
  }

  ngOnInit() {


  }

  simulateTask() {
    for (let i = 0; i < 3; i++) {
      let task = {
        name: "Tarea " + (i + 1) + ".1",
        status: "0",
        icon: '-',
        class: '-'
      };

      this.tasks.push(task);
    }

    this.tasks[0].status = "100%";
    this.tasks[1].status = "75%";
    this.tasks[2].status = "Cancelada";

    this.tasks[0].icon = "check_circle_outline";
    this.tasks[1].icon = "panorama_fish_eye";
    this.tasks[2].icon = "highlight_off";

    this.tasks[0].class = "mat-icon-left green";
    this.tasks[1].class = "mat-icon-left yellow";
    this.tasks[2].class = "mat-icon-left red";
  }

  goTaskDetail(taskName:string){
    localStorage.setItem('taskName', taskName);
    this.router.navigate(['task-detail'])
  }
  
}
