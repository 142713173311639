import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.css', '../../app.component.css']
})
export class IncidencesComponent implements OnInit {
  projectName: string;
  constructor() { this.projectName = localStorage.getItem('projectName');}

  ngOnInit() {
  }

}
