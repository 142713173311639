import { ProjectDetailAltComponent } from './components/project-detail-alt/project-detail-alt.component';
import { ModuleWithProviders, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ListProjectsComponent } from './components/list-projects/list-projects.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectPhasesComponent } from './components/project-phases/project-phases.component';
import { PhasesDetailComponent } from './components/phases-detail/phases-detail.component';
import { ProjectTasksComponent } from './components/project-tasks/project-tasks.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ListIncidentsComponent } from './components/list-incidents/list-incidents.component';
import { ListPortfoliosComponent } from './components/list-portfolios/list-portfolios.component';
import { ListProgramsComponent } from './components/list-programs/list-programs.component';
import { ListProjectsAltComponent } from './components/list-projects-alt/list-projects-alt.component';
import { ProjectAlternatorComponent } from './components/project-alternator/project-alternator.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourcesTasksComponent } from './components/resources-tasks/resources-tasks.component';
import { EditImageComponent } from './components/edit-image/edit-image.component';
import { EditBlueprintComponent } from './components/edit-blueprint/edit-blueprint.component';
import { SincronizerComponent } from './components/sincronizer/sincronizer.component';
import { IncidencesComponent } from './components/incidences/incidences.component';
import { QueueComponent } from './components/queue/queue.component';
import { ProjectBlueprintComponent } from './components/project-blueprint/project-blueprint.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { GanttComponent } from './components/gantt/gantt.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'pruebas', component: GanttComponent},
  { path: 'tasks', component: ResourcesTasksComponent},
  { path: 'portfolios', component: ListPortfoliosComponent },
  { path: 'portfolios/:id/programs', component: ListProgramsComponent },
  { path: 'projects', component: ListProjectsComponent },
  { path: 'sincronize', component: SincronizerComponent },
  { path: 'incidences', component: IncidencesComponent },
  { path: 'queue', component: QueueComponent} ,
  { path: 'documents', component: DocumentsComponent },
  //{ path: 'projects-dashboard', component: ListProjectsAltComponent},
  //{ path: 'projects/:id', component: ProjectDetailComponent }, unused path
  { path: 'projects/:id', component: ProjectDetailAltComponent},
  { path: 'projects/:id/blueprint', component: ProjectBlueprintComponent},
  { path: 'projects/:id/stage', component: ProjectPhasesComponent },
  { path: 'projects/:id/resources', component: ResourcesComponent},
  { path: 'projects/:id/stage/:idStage', component: PhasesDetailComponent },
  { path: 'tasks', component: ProjectTasksComponent }, // Task is not scoped because it can be related to a stage or to an incident
  { path: 'tasks/:id', component: TaskDetailComponent },
  { path: 'incidents', component: ListIncidentsComponent }, // Incident is not scoped because it can be related to a project, stage or task
  // { path: 'incidents/:id', component: null }, // Incident is not scoped because it can be related to project, stage or task
  { path: 'configuration', component: ConfigurationComponent },
  { path: 'editblueprint', component: EditBlueprintComponent},
  { path: 'editimage', component: EditImageComponent},
  { path: 'gantt', component: GanttComponent},
  { path: '**', component: LoginComponent }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
