import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material';
import { getRenderedText } from "@angular/core/src/render3";
import { Portfolio } from 'src/app/models/Portfolio';

var orientability: boolean;
@Component({
  selector: "app-list-portfolios",
  templateUrl: "./list-portfolios.component.html",
  styleUrls: ["./list-portfolios.component.css", "../../app.component.css"]
})
export class ListPortfoliosComponent implements OnInit {
  portfolios: Portfolio[];
  pageEvent: PageEvent;
  numbers: number[];
  orient: boolean;
  constructor(public router: Router, private cdRef: ChangeDetectorRef) {
    this.portfolios = [];
    this.simulatePortfolio();
    this.pageEvent = {
      pageIndex: 0,
      pageSize: 9,
      length: 100
    };
    this.lengthylength(Math.ceil(this.portfolios.length / 9));
  }

  ngOnInit() {this.alert1();}

  simulatePortfolio() {
    for (let i = 0; i < 20; i++) {
      const portfolio = {
        idBack: String(i + 1),
        idLocal: i + 1,
        name: 'Nombre Portfolio ' + (i + 1),
        // percent: '50%',
        departmentName: 'Departamento A',
        creationDate: '21 Enero'
      } as Portfolio;
      this.portfolios.push(portfolio);
    }
  }

  goPortfolioDetail(portfolioName: string, id: number) {
    localStorage.setItem('portfolioName', portfolioName);
    localStorage.setItem('idPortfolio',JSON.stringify(id));
    this.router.navigate(['portfolios/' + id + '/programs']);
  }
  lengthylength(n){
    this.numbers = Array(n).fill(0).map((x,i)=>i);
    }
  goToPage(n) {
      this.pageEvent.pageIndex = n;
  }
  alert1() {
    screen.orientation.onchange = () => {
      if (screen.orientation.type === 'portrait-primary') {
        this.orient = false;
        console.log(this.orient);
      } else {
        this.orient = true;
        console.log(this.orient);
      }
      this.cdRef.detectChanges();
    };
  }
}
