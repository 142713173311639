import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material';

export interface Program {id;
  name;
  percent;
  date;
}

@Component({
  selector: "app-list-programs",
  templateUrl: "./list-programs.component.html",
  styleUrls: ["./list-programs.component.css", "../../app.component.css"]
})
export class ListProgramsComponent implements OnInit {
  programs: Program[];
  pageEvent: PageEvent;
  numbers: number[];
  idPortfolio: any[] = [];

  constructor(public router: Router) {
    this.programs = [];
    this.simulateProgram();
    this.pageEvent = {
      pageIndex: 0,
      pageSize: 9,
      length: 100
    };
    this.lengthylength(Math.ceil(this.programs.length / 9));
    this.idPortfolioGo();
  }

  ngOnInit( ) {}

  simulateProgram() {
    for (let i = 0; i < 20; i++) {
      let program = {id: i+1,
        name: "Nombre Programa "+(i+1),
        percent: "50%",
        date: "21 Enero"
      };

      this.programs.push(program);
    }
  }
  idPortfolioGo() {
    this.idPortfolio.push(localStorage.idPortfolio);
  }
  goBack() {
    this.router.navigate(['portfolios']);
    }
  goProgramsDetail(programsName: string, id: number) {
    localStorage.setItem('programsName', programsName);
    localStorage.setItem('idPrograms', JSON.stringify(id));
    this.router.navigate(['projects']);
  }
  lengthylength(n){ 
    this.numbers = Array(n).fill(0).map((x,i)=>i);
    }
  goToPage(n) {
      this.pageEvent.pageIndex = n;
  }
}
