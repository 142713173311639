import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }
  /*goProjectAlt() {
    //localStorage.setItem('projectName', projectName);
    this.router.navigate(['projects-dashboard']);
  }*/
}
