import { Component, OnInit} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Lines } from '../sub-header-projects/sub-header-projects.component';

@Component({
  selector: 'app-project-detail-alt',
  templateUrl: './project-detail-alt.component.html',
  styleUrls: ['./project-detail-alt.component.css', '../../app.component.css']
})
export class ProjectDetailAltComponent implements OnInit {
  status: boolean;
  projectName:string;
  projectId: string;
  description:string = 'Caesa regio, est surgere. Humanas membra duae effervescere, rectumque lanient postquam pulsant status. Summaque peragebant aetas pondus nullaque aethere mundum tegi. Rudis lege tanta praeter...'

  constructor(private router: Router, private route: ActivatedRoute) {
    this.projectName = localStorage.getItem('projectName');
    this.projectId = localStorage.getItem('projectId');
   }
   lines: Lines[] = [
    {viewValue: 'Linea Base 1'},
    {viewValue: 'Linea Base 2'},
    {viewValue: 'Linea Base 3'}
  ];
  ngOnInit() {this.status = true;
  }

  goProjectPhases() {
    /*let navigationExtras: NavigationExtras = {
      queryParams: {
        projectName: this.projectName
      }
    };*/
    this.router.navigate(['projects/'+this.projectId+'/stage']);
  }
  goResources() {
    this.router.navigate(['projects/'+this.projectId+'/resources'])
    }
  goIncidences() {
    this.router.navigate(['incidences'])
  }
  /*goBlueprint() {
    this.router.navigate(['projects/'+this.projectId+'/blueprint'])
    }*/
  goDocuments() {
    this.router.navigate(['documents'])
    }
}
