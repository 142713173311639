import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ElementRef, Renderer } from '@angular/core';
import { Directive, HostListener } from '@angular/core';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})

export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() component: string;
  @Input() show: boolean;
  constructor(private router:Router, private location:Location
    ) {}

  ngOnInit() {}

  goBack(){
    if (this.component === 'config'){
      this.location.back();
    } else {
      //this.router.navigate([this.component]);
      this.location.back();
    }
  }
 /* alert(){
screen.orientation.onchange = function(){console.log(screen.orientation.type);
    };
  }*/
}
