import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'avintia';
}


/*

import {
  mobiscroll,
  MbscListviewOptions
} from "../lib/mobiscroll/js/mobiscroll.angular.min.js";


 listImages = [
            { name: 'Image 1' },
            { name: 'Image 2' },
            { name: 'Image 3' },
            { name: 'Image 4' },
            { name: 'Image 5' }
        ];

        menuImages = [
            { name: 'My Image 1' },
            { name: 'My Image 2' },
            { name: 'My Image 3' },
            { name: 'My Image 4' },
            { name: 'My Image 5' }
        ];

        listSettings: MbscListviewOptions = {
            theme: 'ios',    // Specify theme like: theme: 'ios' or omit setting to use default
        lang: 'en',          // Specify language like: lang: 'pl' or omit setting to use default
            enhance: true,   // More info about enhance: https://docs.mobiscroll.com/4-5-2/angular/listview#opt-enhance
            stages: [{       // More info about stages: https://docs.mobiscroll.com/4-5-2/angular/listview#opt-stages
                    percent: 25,
                    icon: 'link',
                    text: 'Get link',
                    action: (event, inst) => {
                        mobiscroll.toast({
                            message: 'Link copied'
                        });
                    }
                }, {
                    percent: 50,
                    icon: 'download',
                    text: 'Download',
                    action: (event, inst) => {
                        mobiscroll.toast({
                            message: 'Downloaded'
                        });
                    }
                }, {
                    percent: -25,
                    icon: 'print',
                    text: 'Print',
                    action: (event, inst) => {
                        mobiscroll.toast({
                            message: 'Printing...'
                        });
                    }
                },
                {
                    percent: -50,
                    icon: 'remove',
                    text: 'Delete',
                    confirm: true,
                    action: (event, inst) => {
                        this.listImages.splice(event.index, 1);
                    }
                }
            ]
        };

        menuSettings: MbscListviewOptions = {
            theme: 'ios',    // Specify theme like: theme: 'ios' or omit setting to use default
        lang: 'en',          // Specify language like: lang: 'pl' or omit setting to use default
            enhance: true,   // More info about enhance: https://docs.mobiscroll.com/4-5-2/angular/listview#opt-enhance
            actions: [{      // More info about actions: https://docs.mobiscroll.com/4-5-2/angular/listview#opt-actions
                icon: 'link',
                action: (event, inst) => {
                    mobiscroll.toast({
                        message: 'Linked'
                    });
                }
            }, {
                icon: 'star3',
                action: (event, inst) => {
                    mobiscroll.toast({
                        message: 'Starred'
                    });
                }
            }, {
                icon: 'download',
                action: (event, inst) => {
                    mobiscroll.toast({
                        message: 'Downloaded'
                    });
                }
            }, {
                icon: 'print',
                action: (event, inst) => {
                    mobiscroll.toast({
                        message: 'Printing...'
                    });
                }
            }, {
                icon: 'remove',
                undo: true,  // More info about undo: https://docs.mobiscroll.com/4-5-2/angular/listview#methods-undo
                action: (event, inst) => {
                    this.menuImages.splice(event.index, 1);
                }
            }]
        };

        formSettings = {
            lang: 'en',          // Specify language like: lang: 'pl' or omit setting to use default
            theme: 'ios'     // Specify theme like: theme: 'ios' or omit setting to use default
        };


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { JsonpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MbscModule } from '../lib/mobiscroll/js/mobiscroll.angular.min.js';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MbscModule,
    FormsModule,
    ReactiveFormsModule,
    JsonpModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }



<mbsc-page theme="ios" lang="en">
    <mbsc-form [options]="formSettings">
        <mbsc-form-group>
            <mbsc-form-group-title>Action list</mbsc-form-group-title>
            <mbsc-listview [options]="listSettings">
                <mbsc-listview-item icon="image2" *ngFor="let item of listImages">{{ item.name }}</mbsc-listview-item>
            </mbsc-listview>
        </mbsc-form-group>
        <mbsc-form-group>
            <mbsc-form-group-title>Action menu</mbsc-form-group-title>
            <mbsc-listview [options]="menuSettings">
                <mbsc-listview-item icon="image2" *ngFor="let item of menuImages">{{ item.name }}</mbsc-listview-item>
            </mbsc-listview>
        </mbsc-form-group>
    </mbsc-form>
</mbsc-page>

*/
