import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'config.service';
import { WebStorageService } from '../web-storage.service';
import { Task } from 'src/app/models/Task';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskHttpService {

  private entityUrl = '';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private webStorageService: WebStorageService
  ) {
    const baseUrl = this.configService.apiUrl;
    this.entityUrl = `${baseUrl}/tasks`;
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
  }

  create(task: Task): Observable<any> {

    console.log('Simulando llamada POST a /tasks');
    return;
    const accessToken = this.webStorageService.getItem('access_token');
    return this.http.post(`${this.entityUrl}?access_token=${accessToken}`, task, { headers: this.headers });
  }

  update(task: Task): Observable<any> {

    console.log('Simulando llamada PUT a /tasks');
    return;
    const accessToken = this.webStorageService.getItem('access_token');
    if (task.idBack === undefined) {
      console.error('Attempting to PUT a task with no backend id.');
    }
    return this.http.put(`${this.entityUrl}/${task.idBack}?access_token=${accessToken}`, task, { headers: this.headers });
  }

  delete(task: Task): Observable<any> {

    console.log('Simulando llamada DELETE a /tasks');
    return;
    const accessToken = this.webStorageService.getItem('access_token');
    if (task.idBack === undefined) {
      console.error('Attempting to DELETE a task with no backend id.');
    }
    return this.http.delete(`${this.entityUrl}/${task.idBack}?access_token=${accessToken}`, { headers: this.headers });
  }
}
