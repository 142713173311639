import { ConfigService } from './../../../config.service';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NotificationComponent } from '../components/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  /**
   * This service encapsulates the logic of notifying the user with success or error messages.
   * It also handles debug notifications that can be deactivated.
   */

  constructor(
    private config: ConfigService,
    private snackBar: MatSnackBar
  ) { }

  success(message: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        className: 'success'
      },
      duration: 4000, // ms
      panelClass: [ 'snack-bar-success' ]
    });
  }

  error(message: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        className: 'error'
      },
      // duration: 8000, // ms
      panelClass: [ 'snack-bar-error' ]
    });
  }


  log(message: string) {
    if (this.config.debugMessages) {
      console.log(message);
    }
  }

  logError(message: string) {
    if (this.config.debugMessages) {
      console.error(message);
    }
  }

}
