import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.css', '../../app.component.css']
})
export class QueueComponent implements OnInit {
  projectName: string;
  constructor() { this.projectName = localStorage.getItem('projectName');}

  ngOnInit() {
  }

}
