import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.css', '../../app.component.css']
})
export class EditImageComponent implements OnInit {
  projectName: string;
  constructor() { this.projectName= localStorage.getItem('projectName') }

  ngOnInit() {
  }

}
