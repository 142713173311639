import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-blueprint',
  templateUrl: './edit-blueprint.component.html',
  styleUrls: ['./edit-blueprint.component.css', '../../app.component.css']
})
export class EditBlueprintComponent implements OnInit {
  projectName: string;
  constructor() { this.projectName= localStorage.getItem('projectName') }

  ngOnInit() {
  }

}
