import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
export interface Folder {
  id: number;
  doc: string;
  image: string;
  pdf: string;
}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css', '../../app.component.css']
})

export class DocumentsComponent implements OnInit {
  projectName: string;
  documents: Folder[];
  counter;
  constructor(private router:Router) {this.projectName = localStorage.getItem('projectName');
  this.documents = [];
  this.simulateDocuments();
  this.counter = Object.keys(this.documents[1]);
}

  ngOnInit() {
  }

  simulateDocuments() {    for (let i = 0; i < 3; i++) {
    let document = {id: i+1,
      doc: "Documento "+(i+1),
      image: "Imagen "+(i+1),
      pdf: "PDF "+(i+1)
    };
    this.documents.push(document);}}

  goEditblueprint() {
      this.router.navigate(['editblueprint']);
    }

  goEditimage() {
     this.router.navigate(['editimage']);
    }

}
