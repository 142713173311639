import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchbarService {

  search(term: string): any {
    return term;
  }

constructor() { }

  }
