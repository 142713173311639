import { Component, OnInit} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css' , "../../app.component.css"]
})
export class ProjectDetailComponent implements OnInit {

  projectName:string;
  descripcion:string = 'El proyecto contempla una Terminal de Contenedores, de aproximadamente 38 Ha, con 1.780 m de muelle y 305 de dique de abrigo, más la ampliación y profundización de canal de acceso y el área de maniobras.'

  constructor(private router:Router, private route: ActivatedRoute) {
    this.projectName = localStorage.getItem('projectName');
   }

  ngOnInit() {
  }

  goProjectPhases(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        projectName: this.projectName
      }
    };
    
    
    this.router.navigate(['project-phases'], navigationExtras);
  }

}
