import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-blueprint',
  templateUrl: './project-blueprint.component.html',
  styleUrls: ['./project-blueprint.component.css', '../../app.component.css']
})
export class ProjectBlueprintComponent implements OnInit {
  projectName:string;
  constructor() {this.projectName = localStorage.getItem('projectName');}

  ngOnInit() {
  }

}
