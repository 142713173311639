import { Injectable } from '@angular/core';
import { ConfigService } from 'config.service';

@Injectable({
  providedIn: 'root'
})
export class SecretService {

  constructor (
    private config: ConfigService
  ) {}

  /**
   * Provides the basic config for Azure AD auth
  */
  public get adalConfig(): any {
    return {
      instance: 'https://login.microsoftonline.com/',
      // domain: 'avintia.onmicrosoft.com',
      // tenantId: '785a77cc-15b7-4047-b267-58d7718526a4',
      tenant: 'avintia.onmicrosoft.com',
      clientId: '064a493d-c0d1-4521-92cd-2c73d6a23e74',
      // clientSecret: 'FuI4ZQpHA9qZVON6i26J55WLnZ2ZCz6Bt/nMAHOwKWg=',
      redirectUri: window.location.origin + '/', // this.config.apiUrl,
      postLogoutRedirectUri: window.location.origin + '/', // this.config.apiUrl
      // popUp: true
    };
  }
}
