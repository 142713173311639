import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: "app-login-header",
  templateUrl: "./login-header.component.html",
  styleUrls: ["./login-header.component.css"]
})
export class LoginHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() show: boolean;
  @Input() component: string;



  constructor(private router:Router, private location:Location
    ) {}

  ngOnInit() {}

  goBack(){
    if (this.component === 'config'){
      this.location.back();
    } else {
      this.router.navigate([this.component]);
    }
  }
}
