import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material';
import { MatPaginatorIntl } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { stat } from 'fs';
import { async } from 'q';
import { url } from "inspector";
import {ViewEncapsulation} from '@angular/core';

export interface Project {id;
  name;
  percent;
  dateini;
  datefin;
  status;
  incident;
}
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: "app-list-projects",
  templateUrl: "./list-projects.component.html",
  styleUrls: ["./list-projects.component.css", "../../app.component.css"]
})
export class ListProjectsComponent implements OnInit {
  projects: Project[];
  numbers: number[];
  alter: boolean;
  pageEvent: PageEvent;
  columnsToDisplay: string[] = ['id', 'name', 'dateini', 'datefin', 'status', 'incident'];
  lines: string[] = ['Linea 1', 'Linea 2', 'Linea 3'];
  projectName: string;
  projectId: string;
  constructor(public router: Router, public dialog: MatDialog) {
    this.projects = [];
    this.simulateProject();
    this.alter = true;
    this.pageEvent = {
      pageIndex: 0,
      pageSize: 4,
      length: 100
    };
   this.lengthylength(Math.ceil(this.projects.length / 4));
  }
  ngOnInit() {
  }

  simulateProject() {
    for (let i = 0; i < 20; i++) {
      const rand1 = Math.random();
      if (rand1 < 0.5) {
         var stats = "PARADO"
        } else { var stats = "ACTIVO"
      };
      let project = {id: i+1,
        name: "Nombre Proyecto "+(i+1),
        percent: Math.floor(Math.random()*100)+'%',
        dateini: "21-1-19",
        datefin: "21-7-19",
        status: stats,
        incident: Math.floor(Math.random()*2-1)
      };

      this.projects.push(project);
    }
  }
    goDashboard() {
      this.alter = !this.alter;
    }
   changePag(but: boolean) {
     if(but === true){
     this.pageEvent = {
      pageIndex: 0,
      pageSize: 4,
      length: 100};
      this.lengthylength(Math.ceil(this.projects.length / 4));
    } else {
      this.pageEvent = {
        pageIndex: 0,
        pageSize: 9,
        length: 100};
        this.lengthylength(Math.ceil(this.projects.length / 9));}
      }
   goBack() {
    this.router.navigate(['portfolios/' + localStorage.idPrograms + '/programs']);
    }
    lengthylength(n){
    this.numbers = Array(n).fill(0).map((x, i)=> i);
    }
    goToPage(n) {
      this.pageEvent.pageIndex = n;
    }
    goIncidence(Name: string, id) {
      localStorage.setItem('projectName', Name);
      localStorage.setItem('projectId', JSON.stringify(id));
      this.router.navigate(['incidences'])
    }


    goProjectDetail(Name: string, id) {
    localStorage.setItem('projectName', Name);
    localStorage.setItem('projectId',JSON.stringify(id));
    this.router.navigate(['projects/' + id]);
    }

    openDialog(Name: string, id): void {
      localStorage.setItem('projectName', Name);
      localStorage.setItem('projectId', JSON.stringify(id));
      const dialogRef = this.dialog.open(DashboardPopup, {
      width: '45vw',
      height: '70vw',
      panelClass: 'panel'
      })

      /*dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.animal = result;
      });*/
    }
}



export class MatPaginatorIntlCro extends MatPaginatorIntl {
  itemsPerPageLabel = '';
  nextPageLabel     = '';
  previousPageLabel = '';
  getRangeLabel = function (page: number, pageSize: number, length:number) {
    let n =  Math.ceil(length / pageSize);

    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return  'Página ' + (page + 1) + ' de ' + n;

  };

}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dashboard-popup.html',
  styleUrls: ["./list-projects.component.css", "../../app.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class DashboardPopup {
  projectName = localStorage.getItem('projectName');
  projectId = localStorage.getItem('projectId');
  constructor(public router: Router,
    public dialogRef: MatDialogRef<DashboardPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  goProjectDetail(Name: string, id) {
    localStorage.setItem('projectName', Name);
    localStorage.setItem('projectId', JSON.stringify(id));
    this.router.navigate(['projects/' + id]);
  }
  goIncidence(Name: string, id) {
    localStorage.setItem('projectName', Name);
    localStorage.setItem('projectId', JSON.stringify(id));
    this.router.navigate(['incidences'])
  }
}
