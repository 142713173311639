import { Column, Entity, PrimaryColumn } from 'typeorm/browser';

@Entity()
export class Portfolio {

  public static className = 'Portfolio';

  @PrimaryColumn() // It's not generated because we get it from the backend.
  public idBack: string;

  @Column()
  public name: string;

  @Column()
  public departmentName: string;

  @Column()
  public creationDate: string;

  constructor() {}
}
