import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
export interface Lines {
  viewValue: string;
}
@Component({
  selector: 'app-sub-header-projects',
  templateUrl: './sub-header-projects.component.html',
  styleUrls: ['./sub-header-projects.component.css']
})
export class SubHeaderProjectsComponent implements OnInit {
  lines: Lines[] = [
    {viewValue: 'Linea Base 1'},
    {viewValue: 'Linea Base 2'},
    {viewValue: 'Linea Base 3'}
  ];
  constructor(public router: Router) { }
 alter: boolean;
  ngOnInit() {
  this.alter = true;
  }
    goDashboard() {
      this.alter = !this.alter;
    }
    goProjectAlt() {
      // localStorage.setItem('projectName', projectName);
      this.alter = !this.alter;

    }
  }

