import { PrimaryGeneratedColumn, Column } from 'typeorm/browser';

export class User {

  @PrimaryGeneratedColumn()
  public idLocal: number; // Frontend DB id

  @Column()
  public idBack: number; // Backend id

  @Column()
  public username: string;

  public constructor() {

  }
}
