import { Injectable } from '@angular/core';
import { Repository } from 'typeorm/browser';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { SynchronizeService } from '../synchronize.service';
import { NotifyService } from '../notify.service';
import { User } from 'src/app/models/User';
import { Portfolio } from 'src/app/models/Portfolio';
import { ConfigService } from 'config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthHttp } from 'ng2-adal/dist/core';
import { DeviceService } from '../device/device.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  private localRepository: Repository<Portfolio> = null;
  private currentUser: User = null; // The user that is logged in
  private entityUrl: string;
  private accessToken: string;

  constructor(
    private auth: AuthService,
    private database: DatabaseService,
    private sync: SynchronizeService,
    private notify: NotifyService,
    private http: HttpClient,
    private config: ConfigService,
    private device: DeviceService
  ) {
    this.entityUrl = `${this.config.apiUrlTest}/api/Portfolio`;
  }

  /**
   * Does all async config that cannot be done in the constructor.
   * Creates an array of promises and execute them in parallel for performance.
   */
  private async setup() {
    const promises = [];

    if (this.localRepository == null) {
      promises.push(this.database.getRepository<Portfolio>(Portfolio.className));
    } else {
      promises.push(new Promise(() => this.localRepository)); // Push promise that resolves inmediately.
    }

    if (this.currentUser == null) {
      promises.push(this.auth.getCurrentUser());
    } else {
      promises.push(new Promise(() => this.currentUser)); // Push promise that resolves inmediately.
    }

    // Wait for all promises and get all the values
    [ this.localRepository, this.currentUser ] = await Promise.all(promises);
  }

  /**
   * Get all the portfolios that the current user can see.
   * If connected to the internet, perform API call and store results locally.
   * If no internet, get results locally.
  */
  async findAll(): Promise<Portfolio[]> {
    try {
      await this.setup();

      if (this.device.hasConnection()) {
        // If conn, refresh all data in the local copy and return the local saved instances.
        const data = await this.httpFindAll();
        return this.localRepository.save(data);
      } else {
        // If no conn, just get local copies.
        return this.localRepository.find(); // TODO: remove logically deleted.
      }

    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  /**
   * Get all active Porfolios from the backend.
   * It's currently not paginated, as we need to persist all data in local.
  */
  public async httpFindAll(): Promise<Portfolio[]> {
    return new Promise<Portfolio[]>((resolve, reject) => {

      /*
      this.authHttp.get(`${this.entityUrl}/GetActiveAsync`).subscribe((data: any[]) => {
        console.log(data);
        const portfolios: Portfolio[] = data.map(p => {
          const { id, ...portfolio } = p; // Get all attrs except id.
          portfolio['idBack'] = p.id;
          return portfolio;
        });
        resolve(portfolios);
      }, error => reject(error));
      */

      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
      this.http.get(`${this.entityUrl}/GetActiveAsync`, { headers: headers }).subscribe((data: any[]) => {
        const portfolios: Portfolio[] = data.map(p => {
          const { id, ...portfolio } = p; // Get all attrs except id.
          portfolio['idBack'] = p.id;
          return portfolio;
        });
        resolve(portfolios);
      }, error => reject(error));
    });
  }

  public setAccessToken(token: string) {
    this.accessToken = token;
  }
}
