import { NotifyService } from './notify.service';
import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { QueueItem } from '../models/QueueItem';
import { Repository } from 'typeorm/browser';

/**
 * Handles all the queueing logic.
 * When no internet connection is found, all actions on every entity are queued and used locally.
 * Each action for each entity is queued as a QueueItem in local DB.
 * When connection is established, all local data is updated to the server before getting new data.
 */

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  private localRepository: Repository<QueueItem>;

  constructor(
    private database: DatabaseService,
    private notify: NotifyService
    ) { }

  /**
   * Does all async config that cannot be done in the constructor.
   */
  private async setup() {
    if (!this.localRepository) {
      this.localRepository = await this.database.getRepository<QueueItem>(QueueItem.className);
    }
  }

  /**
   * Add one entity to the queue if not alredady added.
   * @param entityName  The name of the entity type.
   * @param entityIdLocal The local id of the entity.
   */
  async addItem(entityName: string, entityIdLocal: number) {
    await this.setup();

    try {
      const exists = await this.localRepository.findOne({ entityName: entityName, entityIdLocal: entityIdLocal });
      if (!exists) {
        await this.localRepository.create(new QueueItem(entityName, entityIdLocal));
      }
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  /**
   * Gets all the items of the queue.
   */
  async getItems() {
    await this.setup();

    try {
      return this.localRepository.find({ order: { createDate: 'DESC' }}); // Older first
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  async deleteItem(item: QueueItem) {
    await this.setup();
    try {
      return this.localRepository.delete({ id: item.id });
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

}
