import { DeviceService } from './device/device.service';
import { NotifyService } from './notify.service';
import { Injectable } from '@angular/core';
import { QueueService } from './queue.service';
import { ConfigService } from 'config.service';
import { QueueItem } from '../models/QueueItem';
import { Task } from '../models/Task';
import { TaskService } from './task/task.service';
import { IQueueableService } from './queueable.interface';

/**
 * Checks updates from the backend and set all the updates in the local environment.
 */

@Injectable({
  providedIn: 'root'
})
export class SynchronizeService {

  constructor(
    private queue: QueueService,
    private taskService: TaskService,
    private notify: NotifyService,
    private device: DeviceService
  ) {
  }

  /**
   * Checks the backend in order to download fresh data.
   * For each update, it uses the corresponding service to update the local data.
   * It always ensures that all queued changes are sent to the server before getting new data.
   */
  async synchronize() {
    try {
      if (this.device.hasConnection()) {
        const queueItems = await this.queue.getItems();
        if (queueItems.length !== 0) {
          await this.processQueue(queueItems);
        }
        // TODO: process updates from the backend and set them in local DB.
        // Maybe we should not use await here as this sync should happen at the background.
        return this.getChangesFromBackend();
      }
    } catch (error) {
      this.notify.error(error.message);
      this.notify.logError(error);
    }
  }

  private async getChangesFromBackend() {

  }

  private async processQueue(queueItems: QueueItem[]) {
    // It is already sorted from older to newer
    queueItems.forEach(item => {
      // If entity is Task, we will get taskService, etc.
      const service = this.getServiceFromEntityName(item.entityName);
      const entity = service.findOne(item.entityIdLocal);

      if (this.mustDelete(entity)) {
        service.deleteInBackend(entity);

      } else if (this.mustCreate(entity)) {
        service.createInBackend(entity);

      } else if (this.mustUpdate(entity)) {
        service.updateInBackend(entity);
      } else  {
        console.error('Could not determine the action to perform in a queue element');
      }
      this.queue.deleteItem(item);
    });
  }

  /**
   * Returns an instance of a service that must implement IQueueableService.
   * If the service implements that interface, it can be used as a generic service.
   */
  private getServiceFromEntityName(entityName: string): IQueueableService {
    if (entityName === (new Task).className) {
      return this.taskService;
    }
    return this.taskService;
  }

  /**
   * Checks if the entity needs to be created in the backend.
   * Entity can be a task, incident, attached document or attached image, because the others are read-only.
  */
  private mustCreate(entity: any) {
    if (entity.idBack === undefined || entity.deleted === undefined) {
      console.error('The entity does not meet the requirements to be processed by the queue.');
    }
    // If there's no idBack, it's not persisted in the backend.
    return entity.idBack === null && !entity.deleted;
  }

  /**
   * Checks if the entity needs to be updated in the backend.
   * Entity can be a task, incident, attached document or attached image, because the others are read-only.
  */
  private mustUpdate(entity: any) {
    if (entity.idBack === undefined || entity.deleted === undefined) {
      console.error('The entity does not meet the requirements to be processed by the queue.');
    }
    // If idBack is not null, it's already in the backend and changes must be updated.
    return entity.idBack !== null && !entity.deleted;
  }

  /**
   * Checks if the entity needs to be deleted in the backend.
   * Entity can be a task, incident, attached document or attached image, because the others are read-only.
  */
  private mustDelete(entity: any) {
    if (entity.idBack === undefined || entity.deleted === undefined) {
      console.error('The entity does not meet the requirements to be processed by the queue.');
    }
    return entity.deleted;
  }
}
