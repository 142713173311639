import { AuthHttp } from 'ng2-adal/dist/core';
// ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing, appRoutingProviders } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import 'reflect-metadata'; // Typeorm dependency

import 'sql.js';

/************** HAMMER JS *********** */

import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y'
    });

    return mc;
  }
}

// COMPONENTS
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { ListProjectsComponent } from './components/list-projects/list-projects.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectPhasesComponent } from './components/project-phases/project-phases.component';
import { PhasesDetailComponent } from './components/phases-detail/phases-detail.component';
import { ProjectTasksComponent } from './components/project-tasks/project-tasks.component';
import { TasksByJobComponent } from './components/tasks-by-job/tasks-by-job.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ListPortfoliosComponent } from './components/list-portfolios/list-portfolios.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ListProjectsAltComponent } from './components/list-projects-alt/list-projects-alt.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { SubHeaderProjectsComponent } from './components/sub-header-projects/sub-header-projects.component';
import { ProjectAlternatorComponent } from './components/project-alternator/project-alternator.component';
import { ProjectDetailAltComponent } from './components/project-detail-alt/project-detail-alt.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourcesTasksComponent } from './components/resources-tasks/resources-tasks.component';
import { EditImageComponent } from './components/edit-image/edit-image.component';
import { EditBlueprintComponent } from './components/edit-blueprint/edit-blueprint.component';
import { SincronizerComponent } from './components/sincronizer/sincronizer.component';
import { IncidencesComponent } from './components/incidences/incidences.component';
import { QueueComponent } from './components/queue/queue.component';
import { NewTaskButton } from './components/new-task/new-task.component';
import { TaskPopup } from './components/new-task/new-task.component';
import { ProjectBlueprintComponent } from './components/project-blueprint/project-blueprint.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { DashboardPopup } from './components/list-projects/list-projects.component';
import { NewIncidentButton } from './components/new-incident/new-incident.component';
import { IncidentPopup } from './components/new-incident/new-incident.component';
import { GanttComponent } from './components/gantt/gantt.component';
// MATERIAL COMPONENTS
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationComponent } from './components/notification/notification.component';
import { ListIncidentsComponent, DeleteIncidentDialog } from './components/list-incidents/list-incidents.component';
import { ListProgramsComponent } from './components/list-programs/list-programs.component';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
// NPM Material
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { MatPaginatorIntlCro } from './components/list-projects/list-projects.component';
import { ResponsiveModule } from 'ngx-responsive';
import { AdalService } from 'ng2-adal/dist/core';
import { Http, ConnectionBackend } from '@angular/http';

const config = {
  breakPoints: {
      xs: {max: 600},
      sm: {min: 601, max: 959},
      md: {min: 960, max: 1279},
      lg: {min: 1280, max: 1919},
      xl: {min: 1920},
      ipad: {min: 768, max: 1024},
      ipadLandscape: {min: 1024, max: 768}
  },
  debounceTime: 100
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ListProjectsComponent,
    ProjectDetailComponent,
    ProjectPhasesComponent,
    PhasesDetailComponent,
    ProjectTasksComponent,
    TasksByJobComponent,
    TaskDetailComponent,
    ListIncidentsComponent,
    ConfigurationComponent,
    DeleteIncidentDialog,
    NotificationComponent,
    ListPortfoliosComponent,
    ListProgramsComponent,
    SearchBarComponent,
    SubHeaderComponent,
    LoginHeaderComponent,
    SubHeaderProjectsComponent,
    ListProjectsAltComponent,
    ProjectAlternatorComponent,
    ProjectDetailAltComponent,
    ResourcesComponent,
    ResourcesTasksComponent,
    EditImageComponent,
    EditBlueprintComponent,
    SincronizerComponent,
    IncidencesComponent,
    QueueComponent,
    ProjectBlueprintComponent,
    DocumentsComponent,
    DashboardPopup,
    NewIncidentButton,
    IncidentPopup,
    NewTaskButton,
    TaskPopup,
    GanttComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    routing,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatSliderModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatChipsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRadioModule,
    MatBadgeModule,
    MatPaginatorModule,
    NgMatSearchBarModule,
    MatSelectModule,
    MatGridListModule,
    MatMenuModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTabsModule
  ],
  entryComponents: [
    DeleteIncidentDialog, NotificationComponent, DashboardPopup, IncidentPopup, TaskPopup
  ],
  providers: [ appRoutingProviders,
              { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
              { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
              AdalService
            ],
  bootstrap: [AppComponent]
})
export class AppModule {}
