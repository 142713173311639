import { Incident } from './../../models/Incident';
import { Lines } from './../sub-header-projects/sub-header-projects.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material';

export interface Phases {
  name;
  status;
  incident;
}

@Component({
  selector: 'app-project-phases',
  templateUrl: './project-phases.component.html',
  styleUrls: ['./project-phases.component.css' , '../../app.component.css']
})
export class ProjectPhasesComponent implements OnInit {
  numbers: number[];
  projectName:string;
  phases: Phases[];
  pageEvent: PageEvent;
  lines: Lines[] = [
    {viewValue: 'Linea Base 1'},
    {viewValue: 'Linea Base 2'},
    {viewValue: 'Linea Base 3'}
  ];
  constructor(private route:ActivatedRoute, private router:Router) {
    this.phases = [];

    this.simulatePhases();

    this.pageEvent = {
      pageIndex: 0,
      pageSize: 9,
      length: 100
    }

    this.projectName = localStorage.getItem('projectName');

    this.lengthylength(Math.ceil(this.phases.length / 9));
   }
  ngOnInit() {
  }

  simulatePhases(){

    for(let i = 0; i < 20; i++){
      let phase = {
        name: 'Nombre Fase '+(i+1),
        status: 'Terminada',
        incident: Math.floor(Math.random()*2)
      }

      this.phases.push(phase);
    }
  }

  goPhasesDetail(phaseName:string){
    localStorage.setItem('phaseName', phaseName);
    this.router.navigate(['tasks']);
  }
  lengthylength(n) {
    this.numbers = Array(n).fill(0).map((x, i) => i);
    }
  goToPage(n) {
    this.pageEvent.pageIndex = n;
  }
  goIncidence(projectName: string, id: number) {
    this.router.navigate(['incidences'])
  }
}
