import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

declare var Gantt;

/*
export interface Gridtile {
  columns: number;
  name: string;
}
*/

export class GanttTask {
  id: string;
  name: string;
  start: string; // 2016-12-3
  end: string;
  progress: number;
  dependencies?: string; // 'id1, id2'
  custom_class?: string;
  custom_popup_html: Function;
}

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['../../app.component.css', './gantt.component.css']
})
export class GanttComponent implements OnInit {

  projectName: string;
  // gridtiles: Gridtile[];
  ganttTasks: GanttTask[];
  ganttInstance: any;

  constructor() {
    this.projectName = localStorage.getItem('projectName');
    // this.gridtiles = [{columns: 31, name: 'Jan'},{columns: 28, name: 'Feb'},{columns: 31, name: 'Mar'},{columns: 30, name: 'Apr'},{columns: 31, name: 'May'},
    // {columns: 30, name: 'June'},{columns: 31, name: 'July'},{columns: 31, name: 'Aug'},{columns: 30, name: 'Sep'},{columns: 31, name: 'Oct'},
    // {columns: 30, name: 'Nov'},{columns: 31, name: 'Dec'}];}
  }

  ngOnInit() {
    this.ganttTasks = [
      {
        id: '1',
        name: 'Replanteo, alineaciones, etc',
        start: '2016-12-1',
        end: '2016-12-3',
        progress: 100,
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '2',
        name: 'Movimientos de tierra',
        start: '2016-12-3',
        end: '2016-12-8',
        progress: 100,
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '3',
        name: 'Cimentación',
        start: '2016-12-7',
        end: '2016-12-14',
        progress: 100,
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '4',
        name: 'Saneamiento',
        start: '2016-12-14',
        end: '2016-12-16',
        progress: 100,
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '5',
        name: 'Estructura y cubierta',
        start: '2016-12-16',
        end: '2016-12-18',
        progress: 100,
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '6',
        name: 'Revestimientos interiores',
        start: '2016-12-18',
        end: '2016-12-26',
        progress: 100,
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '7',
        name: 'Replanteo, alineaciones, etc',
        start: '2016-12-1',
        end: '2016-12-3',
        progress: 100,
        custom_class: 'baseline-gantt',
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '8',
        name: 'Movimientos de tierra',
        start: '2016-12-3',
        end: '2016-12-9',
        progress: 100,
        custom_class: 'baseline-gantt',
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '9',
        name: 'Cimentación',
        start: '2016-12-10',
        end: '2016-12-14',
        progress: 100,
        custom_class: 'baseline-gantt',
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '10',
        name: 'Saneamiento',
        start: '2016-12-14',
        end: '2016-12-16',
        progress: 100,
        custom_class: 'baseline-gantt',
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '11',
        name: 'Estructura y cubierta',
        start: '2016-12-16',
        end: '2016-12-18',
        progress: 100,
        custom_class: 'baseline-gantt',
        custom_popup_html: this.onClickGanttBar
      },
      {
        id: '12',
        name: 'Revestimientos interiores',
        start: '2016-12-18',
        end: '2016-12-24',
        progress: 100,
        custom_class: 'baseline-gantt',
        custom_popup_html: this.onClickGanttBar
      }
    ];
    this.ganttInstance = new Gantt('#gantt', this.ganttTasks, {
      custom_popup_html: this.onClickGanttBar
    });
  }


  onClickGanttBar(task: GanttTask) {
    return `
    <div style="width: 200px; background-color: white; margin: 8px; min-width: 200px;">
      <p style="font-size: 16px; color: black">${task.name}</p>
      <p style="font-size: 12px; color: black">Inicio: ${moment(task.start, 'YYYY-MM-DD').format('DD-MM-YYYY')}</p>
      <p style="font-size: 12px; color: black">Fin: ${moment(task.end, 'YYYY-MM-DD').format('DD-MM-YYYY')}</p>
      <p style="font-size: 12px; color: black"><strong>Inicio esperado: ${moment(task.start, 'YYYY-MM-DD').format('DD-MM-YYYY')}</strong></p>
      <p style="font-size: 12px; color: black"><strong>Fin esperado: ${moment(task.start, 'YYYY-MM-DD').format('DD-MM-YYYY')}</strong></p>
    </div>
    `;
  }

  convertGanttDate = (dateStr) => moment(dateStr, 'YYYY-MM-DD').format('DD-MM-YYYY');

  save() {
    //this.router.navigate(['incidents']);
  }
}
